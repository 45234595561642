import React from 'react';
import './allservices.css';

export default function Seo(){
    return(
        <div className='allservices'>
            <div className='allserviceunder'>
                <div className='allservicediv'>
                    <div>
                        <h1 data-aos="fade-left" data-aos-delay="0" data-aos-duration="600">Why should you go with Search Engine Optimization?</h1>
                        <p data-aos="fade-left" data-aos-delay="200" data-aos-duration="600">A visually appealing website isn't enough. You must be able to contact new clients through SEO. Boosting your presence in applicable, while improving the quality and quantity of website traffic from organic, editorial or natural search researches on search engine websites. We are life-long learners that understand how IT and digital marketing are developing throughout the world and adapt them to our clients as quickly as possible. We design your company's identity from the bottom up and transform it into an ideal brand for your target clients.</p>
                    </div>
                    {/* <div style={{width: "45%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div className='animate1'></div>
                        <div className='animate2'></div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}