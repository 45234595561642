import React from 'react';
import './brandingportfolio.css';
// import brand1 from './images/brand1.jpg'
import image1 from './images/3.png';
import image2 from './images/12.png';
import image3 from './images/14.png';
import image4 from './images/17.png';
import image5 from './images/21.png';
import image6 from './images/23.png';

export default function Brandingportfolio(){
    return(
        <div className='brandingportfolio1'>
            <div className='brandingportfoliounder'>
                <div className='brandingfirst'>
                    <div className='first'>
                        <img src={image1}></img>
                    </div>
                    <div className='second'>
                        <img src={image2}></img>
                    </div>
                    <div className='third'>
                        <img src={image3}></img>
                    </div>
                    <div className='fourth'>
                        <img src={image4}></img>
                    </div>
                    <div className='fifth'>
                        <img src={image5}></img>
                    </div>
                    <div className='sixth'>
                        <img src={image6}></img>
                    </div>
                </div>
            </div>
        </div>
    )
}