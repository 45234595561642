import React from "react";
import "./middlepart2.css";

export default function Middlepart2(){

    return(
        <div className="middlepart2main">
            <div className="middlepart2first">
                <p className="text4" data-aos="fade-left" data-aos-delay="0" data-aos-duration="600">RESULT-DRIVEN SOLUTIONS</p>
            </div>
            <div className="middlepart2second">
                <p className="text5" data-aos="fade-right" data-aos-delay="200" data-aos-duration="600">In today’s world, no defined pattern makes sense!</p>
            </div>
            <div className="middlepart2third">
                <p className="text6" data-aos="fade-left" data-aos-delay="300" data-aos-duration="600">We are life-long learning experts with a strong focus on generating results and boosting brand trust. We are different because we believe in bringing new ideas to the table for every client. Our work is driven by a thorough research and understanding customized for each brand to unlock its own potential value.</p>
            </div>
        </div>
    );
}