import React from 'react';
import './whatsappicon.css';

export default function Whatsappicon(){
    return(
        <div className='whatsapp_float'>
            <a href="https://wa.me/03111144430" className="whatsapp_float" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-whatsapp whatsapp-icon"></i>
            </a>
        </div>
    )
}