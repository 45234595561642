import React from 'react';
import './logodesignportfolio.css';
import icon1 from './images/jwella.png'
import icon2 from './images/oktra.png'
import icon3 from './images/shopizia.png'
import icon4 from './images/diginerds.png'
import icon5 from './images/greatex.png'
import icon6 from './images/Beautibounty.png'

export default function Logodesignportfolio(){
    return(
        <div className='logodesignportfolio1'>
            <div className='logodesignportfoliounder'>
                <div className='logodesignfirst'>
                    <div className='first1'>
                        <img src={icon1}></img>
                    </div>
                    <div className='second1'>
                        <img src={icon2}></img>
                    </div>
                    <div className='third1'>
                        <img src={icon3}></img>
                    </div>
                    <div className='fourth1'>
                        <img src={icon4}></img>
                    </div>
                    <div className='fifth1'>
                        <img src={icon5}></img>
                    </div>
                    <div className='sixth1'>
                        <img src={icon6}></img>
                    </div>
                </div>
            </div>
        </div>
    )
}