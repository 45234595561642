// import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import Middlepart1 from './components/middlepart1';
import Tipprocess from './components/tipprocess';
import Services from './components/services';
import Contactus from './components/contactus';
import Middlepart2 from './components/middlepart2';
import Navigationbar from './components/navigationbar';
import Privacypolicy from './components/privacy';
import Sidebarcontact from './components/sidebarcontact';
import Branding from './components/branding';
import Seo from './components/seo';
import Marketing from './components/marketing';
import Digital from './components/digital';
import Video from './components/video';
import Photography from './components/photography';
import Web from './components/web';
import Appdev from './components/appdev';
import Content from './components/content';
import Sidebarservices from './components/sidebarservices';
import Portfolio from './components/portfolio';
import Brandingportfolio from './components/brandingportfolio';
import Logodesignportfolio from './components/logodesignportfolio';
import Whatsappicon from './components/whatsappicon';
import Termsandconditions from './components/termsandconditions';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={
            <div>
              <Navigationbar></Navigationbar>
              <Sidebarcontact></Sidebarcontact>
              <Whatsappicon></Whatsappicon>
              <Header></Header>
              <Middlepart1></Middlepart1>
              <Tipprocess></Tipprocess>
              <Services></Services>
              <Middlepart2></Middlepart2>
              <Contactus></Contactus>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/aboutus" element={
            <div>
              <Navigationbar></Navigationbar>
              <Whatsappicon></Whatsappicon>
              <Header></Header>
              <Sidebarcontact></Sidebarcontact>
              <Middlepart1></Middlepart1>
              <Middlepart2></Middlepart2>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/contactus" element={
            <div>
              <Navigationbar></Navigationbar>
              <Whatsappicon></Whatsappicon>
              <Header></Header>
              <Contactus></Contactus>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/casestudies" element={
            <div>
              <Navigationbar></Navigationbar>
              <Whatsappicon></Whatsappicon>
              <Header></Header>
              <Portfolio></Portfolio>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/brandingportfolio" element={
            <div>
              <Navigationbar></Navigationbar>
              <Whatsappicon></Whatsappicon>
              <Header></Header>
              <Brandingportfolio></Brandingportfolio>
              <Footer></Footer>
            </div>
          }>
          </Route>
          {/* <Route path="/webdesignportfolio" element={
            <div>
              <Navigationbar></Navigationbar>
              <Header></Header>
              <Webdesignportfolio></Webdesignportfolio>
              <Footer></Footer>
            </div>
          }>
          </Route> */}
          <Route path="/logodesignportfolio" element={
            <div>
              <Navigationbar></Navigationbar>
              <Whatsappicon></Whatsappicon>
              <Header></Header>
              <Logodesignportfolio></Logodesignportfolio>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/privacypolicy" element={
            <div>
              <Navigationbar></Navigationbar>
              <Whatsappicon></Whatsappicon>
              <Header></Header>
              <Privacypolicy></Privacypolicy>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/termsandconditions" element={
            <div>
              <Navigationbar></Navigationbar>
              <Whatsappicon></Whatsappicon>
              <Header></Header>
              <Termsandconditions></Termsandconditions>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/branding" element={
            <div>
              <Navigationbar></Navigationbar>
              {/* <Whatsappicon></Whatsappicon> */}
              <Header></Header>
              <Sidebarservices></Sidebarservices>
              <Branding></Branding>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/seo" element={
            <div>
              <Navigationbar></Navigationbar>
              {/* <Whatsappicon></Whatsappicon> */}
              <Header></Header>
              <Sidebarservices></Sidebarservices>
              <Seo></Seo>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/web" element={
            <div>
              <Navigationbar></Navigationbar>
              {/* <Whatsappicon></Whatsappicon> */}
              <Header></Header>
              <Sidebarservices></Sidebarservices>
              <Web></Web>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/app" element={
            <div>
              <Navigationbar></Navigationbar>
              {/* <Whatsappicon></Whatsappicon> */}
              <Header></Header>
              <Sidebarservices></Sidebarservices>
              <Appdev></Appdev>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/content" element={
            <div>
              <Navigationbar></Navigationbar>
              {/* <Whatsappicon></Whatsappicon> */}
              <Header></Header>
              <Sidebarservices></Sidebarservices>
              <Content></Content>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/marketing" element={
            <div>
              <Navigationbar></Navigationbar>
              {/* <Whatsappicon></Whatsappicon> */}
              <Header></Header>
              <Sidebarservices></Sidebarservices>
              <Marketing></Marketing>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/digital" element={
            <div>
              <Navigationbar></Navigationbar>
              {/* <Whatsappicon></Whatsappicon> */}
              <Header></Header>
              <Sidebarservices></Sidebarservices>
              <Digital></Digital>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/video" element={
            <div>
              <Navigationbar></Navigationbar>
              {/* <Whatsappicon></Whatsappicon> */}
              <Header></Header>
              <Sidebarservices></Sidebarservices>
              <Video></Video>
              <Footer></Footer>
            </div>
          }>
          </Route>
          <Route path="/photography" element={
            <div>
              <Navigationbar></Navigationbar>
              {/* <Whatsappicon></Whatsappicon> */}
              <Header></Header>
              <Sidebarservices></Sidebarservices>
              <Photography></Photography>
              <Footer></Footer>
            </div>
          }>
          </Route>
        </Routes>
        {/* <Navigationbar></Navigationbar>
        <Sidebarcontact></Sidebarcontact>
        <Header></Header>
        <Middlepart1></Middlepart1>
        <Tipprocess></Tipprocess>
        <Services></Services>
        <Middlepart2></Middlepart2>
        <MainServices></MainServices>
        <Contactus></Contactus>
        <Privacypolicy></Privacypolicy>
        <Footer></Footer> */}
      </div>
    </Router>
  );
}

export default App;
