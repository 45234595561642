import React from 'react';
import './allservices.css';

export default function Web(){
    return(
        <div className='allservices'>
            <div className='allserviceunder'>
                <div className='allservicediv'>
                    <div>
                        <h1 data-aos="fade-left" data-aos-delay="0" data-aos-duration="600">Why should you go with Website Design and Development?</h1>
                        <p data-aos="fade-left" data-aos-delay="200" data-aos-duration="600">A website is a must at times to build and maintain the image of your brand. Your brand plays a big role in marketing tools that boosts up the sales and convince your customer to think of you first. The website contains all the artificially intelligent features that a good site requires, from being user friendly to having visually appealing pages, this also ensures the rise in sale and marketing. Websites also need regular checkup and adjustments in order for it to flourish.</p>
                    </div>
                    {/* <div style={{width: "45%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div className='animate1'></div>
                        <div className='animate2'></div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}