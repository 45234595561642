import React from 'react';
import './allservices.css';

export default function Digital(){
    return(
        <div className='allservices'>
            <div className='allserviceunder'>
                <div className='allservicediv'>
                    <div>
                        <h1 data-aos="fade-left" data-aos-delay="0" data-aos-duration="600">Why should you go with Digital Marketing?</h1>
                        <p data-aos="fade-left" data-aos-delay="200" data-aos-duration="600">In the world of digital media, almost all the people around the world are connected through a web around the world which we call it social media or digital media. This is why we need to stay updated and work according to the generational needs. Our approach can be customized according to our customer’s needs such as reels, or brand awareness. Several essential variables that can help your business succeed, such as driving your brand, monitoring, and reporting on social media development, can make a huge impact in your brand's success.</p>
                    </div>
                    {/* <div style={{width: "45%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div className='animate1'></div>
                        <div className='animate2'></div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}