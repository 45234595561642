import React from "react";
import "./header.css";
import digiToez from './images/digiToez.png';
import digi from './images/videoback.mp4'

export default function Header() {

    return (
        <div className="container">
            <video src={digi} autoPlay muted loop></video>
            <div id="logo" onClick={() =>{window.location = '/';}}>
                <img src={digiToez}></img>
            </div>
            <section className="hero">
                <h1>We are creative, <br/><span>Proactive & Reactive</span></h1>
                <div className="button">
                    <a href="/contactus" className="btn1">Contact Us</a>
                    <a href="/branding" className="btn2">Learn More</a>
                </div>
            </section>
        </div>
    );
}