import React, { useState } from 'react';
import './contactus.css';
import { MdRoom, MdCall } from "react-icons/md";
import { send } from 'emailjs-com';

export default function Contactus(){

    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [message, setmessage] = useState("");
    const [number, setnumber] = useState("");
    const [toSend, setToSend] = useState({
        cusmessage: '',
        toemail: '',
        clientname: '',
        cusnumber: ''
    });

    function submitform(){
        toSend.cusmessage = message;
        toSend.toemail = email;
        toSend.clientname = name;
        toSend.cusnumber = number;
        console.log(name);
        console.log(toSend.clientname);
        if(toSend.clientname === ""){
            alert("Enter Your Name");
        }
        else if(toSend.toemail === ""){
            alert("Enter your Email");
        }
        else if(toSend.cusnumber === ""){
            alert("Enter your Number");
        }
        else if(toSend.cusmessage === ""){
            alert("Type your Query!!");
        }
        else{
            send(
                'service_bg0exvp',
                'template_rvbir54',
                toSend,
                "OgfFqtF0e6m83Fkcb",
            )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setmessage('');
                setnumber('');
                setemail('');
                setname('');
            })
            .catch((err) => {
                console.log('FAILED...', err);
            });
        }
    }

    return(
        <div className="contactusmain" id='contactus'>
            <div className='contactuslower'>
                <div className='hoverback'>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                    <div className='smallbox'></div>
                </div>
                <div className="contactusform">
                    <div className="contactusheading">
                        <h1>Contact Us</h1>
                    </div>
                    <div className="form2">
                        <form>
                            <input onChange={(e) => setname(e.target.value)} value={name} type="text" className="name" required placeholder="Name"></input>
                            <input onChange={(e) => setemail(e.target.value)} value={email} type="email" className="email" required placeholder="Email"></input>
                            <input onChange={(e) => setnumber(e.target.value)} value={number} type="number" className="number" required placeholder="Number"></input>
                            <textarea onChange={(e) => setmessage(e.target.value)} value={message} id="message" name="message" rows="6" required placeholder="Type your services here.."></textarea>
                        </form>
                    </div>
                    <div className="subbutton" onClick={submitform}>
                        <p>Submit</p>
                    </div>
                </div>
                <div className="contactusdeatils">
                    <div className="intouch">
                        <h1>Get In Touch</h1>
                        <p style={{fontSize: "18px", fontWeight: "600"}}>We're just a thought away! Reach out to us.</p>
                        <p>We are life-long learning experts with a strong focus on generating results and boosting brand trust. Our work is driven by a thorough research and understanding customized for each brand to unlock its own potential value.</p>
                    </div>
                    <div className="bushours">
                        <h1>Contact Info</h1>
                        <p><MdRoom className='time'></MdRoom> 104 Business Forum Khalid bin waleed road Karachi Pakistan</p>
                        <p><MdCall className='time'></MdCall> +923 111 144 430</p>
                    </div>
                </div>
            </div>
        </div>
    );
}