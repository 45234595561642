import React, { useEffect, useState } from "react";
import './sidebarcontact.css';
import { send } from 'emailjs-com';

export default function Sidebarcontact(){

    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [parentHover1, setparentHover1] = useState(false);
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [number, setnumber] = useState("");
    const [select, setselect] = useState("Logo Design");
    const [toSend, setToSend] = useState({
        cusnumber: '',
        toemail: '',
        clientname: '',
        selectedoption: '',
    });

    function submitform(){
        toSend.cusnumber = number;
        toSend.toemail = email;
        toSend.clientname = name;
        toSend.selectedoption = select;
        console.log(name);
        console.log(toSend.clientname);
        if(toSend.clientname === ""){
            alert("Enter Your Name");
        }
        else if(toSend.toemail === ""){
            alert("Enter your Email");
        }
        else if(toSend.cusnumber === ""){
            alert("Enter your Number");
        }
        else{
            send(
                'service_bg0exvp',
                'template_rvbir54',
                toSend,
                "OgfFqtF0e6m83Fkcb",
            )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setnumber('');
                setemail('');
                setname('');
            })
            .catch((err) => {
                console.log('FAILED...', err);
            });
        }
    }

    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
        const onScroll = e => {
            setScrollTop(window.scrollY);
            setScrolling(e.target.documentElement.scrollTop > scrollTop);
          //   console.log(scrolling);
            console.log(scrollTop);
            if(scrollTop > 600){
              setparentHover1(true);
            }
            else if(scrollTop < 600){
                setparentHover1(false);
            }
        };
        window.addEventListener("scroll", onScroll);
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
          window.removeEventListener("scroll", onScroll);
        };
      }, [scrollTop, scrolling]);

    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        // console.log(window.scrollY);
        return {innerWidth, innerHeight};
    }


    useEffect(() => {
        var sidebut = document.querySelector('.sidebar-form');
        var sideform = document.querySelector('.call-action');
        var close = document.querySelector('.close');
        function openform(event) {
            sidebut.classList.toggle('show');
            event.preventDefault();
            event.stopImmediatePropagation();
        }
        sideform.addEventListener('click', openform, false);
        close.addEventListener('click', openform, false);
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            var sidebut = document.querySelector('.sidebar-form');
            var sideform = document.querySelector('.call-action');
            // function openform(event) {
                sidebut.classList.toggle('show');
                // event.preventDefault();
                // event.stopImmediatePropagation();
            // }
            // sideform.addEventListener('click', openform, false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return(
        <div className="sidebar-form" style={parentHover1 ? {opacity: "1"} : {opacity: "0"}}>
            <div className="close"><p>x</p></div>
            <div className="call-action">
                <span>GET 70% DISCOUNT</span>
            </div>
            <div className="form">
                <h3>YOUR FIRST STEP TOWARDS A GREAT LOGO</h3>
                <form>
                    <input onChange={(e) => setname(e.target.value)} value={name} type="text" placeholder="FULL NAME" required></input>
                    <input onChange={(e) => setnumber(e.target.value)} value={number} type="text" placeholder="PHONE NUMBER" required></input>
                    <input onChange={(e) => setemail(e.target.value)} value={email} type="email" placeholder="EMAIL ADDRESS" required></input>
                    <select name="services" id="ser" value={select} onChange={e => setselect(e.target.value)}>
                        <option value="Logo-Design">Logo Design</option>
                        <option value="SEO">SEO</option>
                        <option value="Branding">Branding</option>
                        <option value="Marketing Colletaeral">Marketing Collateral</option>
                        <option value="Content Writing">Content Writing</option>
                        <option value="Photography">Photography</option>
                        <option value="Website-design">Website Design</option>
                        <option value="Animation">Animation</option>
                        <option value="Mobile-Apps">Mobile Apps</option>
                    </select>
                    <p onClick={submitform} style={{cursor: "pointer"}}>Submit</p>
                </form>
            </div>
	    </div>
    );
}