import React from 'react';
import './allservices.css';

export default function Appdev(){
    return(
        <div className='allservices'>
            <div className='allserviceunder'>
                <div className='allservicediv'>
                    <div>
                        <h1 data-aos="fade-left" data-aos-delay="0" data-aos-duration="600">Why should you go with App Development and Designing</h1>
                        <p data-aos="fade-left" data-aos-delay="200" data-aos-duration="600">Not all brands need an application but some do require an app, since we are living in a digital world, we need to stay up to date. Which means the application of your brand should be customized or highly appealing. We select App functionalities and features to build on based on the nature of your organization and the areas in which it should thrive. We understand how important it is for businesses to provide solutions that meet the needs of their customers, therefore we make every effort to fully use the platform and bring them all together for you in one location.</p>
                    </div>
                    {/* <div style={{width: "45%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div className='animate1'></div>
                        <div className='animate2'></div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}