import React, {useEffect} from 'react';
import './navigationbar.css';
import { FaBars } from 'react-icons/fa';
import { IoLogoFacebook, IoLogoLinkedin, IoLogoInstagram } from 'react-icons/io';

export default function Navigationbar(){

    useEffect(() => {
        var navButton = document.querySelector('.navigation-button');
        var navMenu = document.querySelector('.navigation-menu');
        var win = window;

        let menuToggle = document.querySelector('.menuToggle');
        menuToggle.onclick = function(){
            menuToggle.classList.toggle('active')
        }
        
        function openMenu(event) {
            navButton.classList.toggle('active');
            navMenu.classList.toggle('active');
            event.preventDefault();
            event.stopImmediatePropagation();
        }
        
        function closeMenu(event) {
            if(navButton.classList.contains('active')) {
                navButton.classList.remove('active');
                navMenu.classList.remove('active');
            }
        }
        navButton.addEventListener('click', openMenu, false);
        win.addEventListener('click',closeMenu, false); 
    });

    return(
        <div>
            <div className="navigation-wrapper">
                <div className="navigation-button">
                    <i className="fa"><FaBars></FaBars></i>
                </div>
                <div className="navigation-menu">
                    <ul>
                        <li onClick={() =>{window.location = '/';}}><a href="/">HOME</a></li>
                        <li onClick={() =>{window.location = '/branding';}}><a href="/branding">SERVICES</a></li>
                        <li onClick={() =>{window.location = '/casestudies';}}><a href="/casestudies">CASE STUDIES</a></li>
                        <li onClick={() =>{window.location = '/aboutus';}}><a href="/aboutus">ABOUT</a></li>
                        <li onClick={() =>{window.location = '/contactus';}}><a href="/contactus">CONTACT</a></li>
                    </ul>
                </div>
            </div>
            <div className="navigate">
                <div className="menuToggle"></div>
                <div className="menu">
                    <ul>
                        <li><a href="https://www.facebook.com/digitaltoez.pk"><IoLogoFacebook></IoLogoFacebook></a></li>
                        <li><a href="https://www.instagram.com/digitaltoez.pk"><IoLogoInstagram></IoLogoInstagram></a></li>
                        <li><a href="https://www.linkedin.com/company/74018710/admin/"><IoLogoLinkedin></IoLogoLinkedin></a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}