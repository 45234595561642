import React from 'react';
import './allservices.css';

export default function Branding(){
    return(
        <div className='allservices'>
            <div className='allserviceunder'>
                <div className='allservicediv'>
                    <div>
                        <h1 data-aos="fade-left" data-aos-delay="0" data-aos-duration="600">Why should you go with Branding Solutions?</h1>
                        <p data-aos="fade-left" data-aos-delay="200" data-aos-duration="600">A strong brand is always reflected in its visuals, but we also need to make your brand standout while delivering the right message and product image to our clients. One of the most critical parts of a successful business is branding. It's the initial impression you create on your audience, and it's what sets you apart from the competitors. Your brand reflects exactly what your clients should anticipate from you. We like assisting businesses in developing flawless branding that reflects their aims, culture, and values.</p>
                    </div>
                    {/* <div style={{width: "45%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div className='animate1'></div>
                        <div className='animate2'></div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}