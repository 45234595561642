import React from 'react';
import './sidebarservices.css';
import digiToez from './images/digiToez.png';
import { NavLink } from 'react-router-dom';
import {TbBrandGoogleAnalytics} from 'react-icons/tb';
import {MdDesignServices, MdDeveloperMode, MdPhotoCamera, MdVideocam, MdOutlineWeb, MdMarkAsUnread} from 'react-icons/md';
import {AiOutlineCloudServer} from 'react-icons/ai';
import {DiDigitalOcean} from 'react-icons/di';

export default function Sidebarservices(){
    return(
        <div className='sidebarservices'>
            <nav>
                <ul>
                    <li className='logomain'>
                        <NavLink to="/" className="logo">
                            <img src={digiToez} alt="logo"></img>
                        </NavLink>
                    </li>
                    <li><NavLink to="/branding">
                        <TbBrandGoogleAnalytics className='fa'></TbBrandGoogleAnalytics>
                        <span className="nav-item">Branding</span>
                    </NavLink></li>
                    <li><NavLink to="/seo">
                        <AiOutlineCloudServer className='fa'></AiOutlineCloudServer>
                        <span className="nav-item">SEO</span>
                    </NavLink></li>
                    <li><NavLink to="/marketing">
                        <MdMarkAsUnread className='fa'></MdMarkAsUnread>
                        <span className="nav-item">Marketing</span>
                    </NavLink></li>
                    <li><NavLink to="/content">
                        <MdDesignServices className='fa'></MdDesignServices>
                        <span className="nav-item">Content Writing</span>
                    </NavLink></li>
                    <li><NavLink to="/web">
                        <MdOutlineWeb className='fa'></MdOutlineWeb>
                        <span className="nav-item">Web Development</span>
                    </NavLink></li>
                    <li><NavLink to="/video">
                        <MdVideocam className='fa'></MdVideocam>
                        <span className="nav-item">Video & Animation</span>
                    </NavLink></li>
                    <li><NavLink to="/app">
                        <MdDeveloperMode className='fa'></MdDeveloperMode>
                        <span className="nav-item">App Development</span>
                    </NavLink></li>
                    <li><NavLink to="/photography">
                        <MdPhotoCamera className='fa'></MdPhotoCamera>
                        <span className="nav-item">Photography</span>
                    </NavLink></li>
                    <li><NavLink to="/digital">
                        <DiDigitalOcean className='fa'></DiDigitalOcean>
                        <span className="nav-item">Digital Marketing</span>
                    </NavLink></li>
                </ul>
            </nav>
        </div>
    )
}