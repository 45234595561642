import React from 'react';
import './allservices.css';

export default function Content(){
    return(
        <div className='allservices'>
            <div className='allserviceunder'>
                <div className='allservicediv'>
                    <div>
                        <h1 data-aos="fade-left" data-aos-delay="0" data-aos-duration="600">Why should you go with Content Writing?</h1>
                        <p data-aos="fade-left" data-aos-delay="200" data-aos-duration="600">The primary focus of our content writing services revolves around your target audience. We develop extremely attractive content for your consumers that fosters trust, intimacy, and premium sales. The primary goal is to bridge the gap between your product and the hearts of your clients.</p>
                        <p data-aos="fade-left" data-aos-delay="400" data-aos-duration="600">The approach begins with understanding your business and the value proposition that offers you a competitive advantage in the market. Following that, we do extensive consumer research with the goal of getting to know your customers better than they know themselves.</p>
                    </div>
                    {/* <div style={{width: "45%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div className='animate1'></div>
                        <div className='animate2'></div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}