import React from 'react';
import './portfolio.css';
import brand from './images/brand.jpg';
import logo from './images/cover.jpg'
import { NavLink } from 'react-router-dom';

export default function Portfolio(){
    return(
        <div className='mainportfolio'>
            <div className='portfoliounder'>
                <NavLink to={'/brandingportfolio'}>
                    <section className='brandingportfolio'>
                        <div className='brandingimage' data-aos="fade-right" data-aos-delay="0" data-aos-duration="600">
                            <img src={logo}></img>
                        </div>
                        <div className='brandingcontent'>
                            <h5 data-aos="fade-right" data-aos-delay="100" data-aos-duration="600">Branding</h5>
                            <div data-aos="fade-right" data-aos-delay="200" data-aos-duration="600"></div>
                            <h6 data-aos="fade-right" data-aos-delay="300" data-aos-duration="600">Portfolio</h6>
                            <p data-aos="fade-right" data-aos-delay="400" data-aos-duration="600">A strong brand is always reflected in its visuals. Make your brand identity deliver the right message to the right audience.</p>
                        </div>
                    </section>
                </NavLink>
                <NavLink to={'/logodesignportfolio'}>
                    <section className='logodesignportfolio'>
                        <div className='logodesignimage' data-aos="fade-left" data-aos-delay="0" data-aos-duration="600">
                            <img src={logo}></img>
                        </div>
                        <div className='logodesigncontent'>
                            <h5 data-aos="fade-left" data-aos-delay="100" data-aos-duration="600">Logo Design</h5>
                            <div data-aos="fade-left" data-aos-delay="200" data-aos-duration="600"></div>
                            <h6 data-aos="fade-left" data-aos-delay="300" data-aos-duration="600">Portfolio</h6>
                            <p data-aos="fade-left" data-aos-delay="400" data-aos-duration="600">Empower Your Brand With Custom Logo Designs</p>
                        </div>
                    </section>
                </NavLink>
                {/* <NavLink to={'/webdesignportfolio'}>
                    <section className='webdesignportfolio'>
                        <div className='webdesignimage' data-aos="fade-up" data-aos-delay="0" data-aos-duration="600">
                            <img src={brand}></img>
                        </div>
                        <div className='webdesigncontent'>
                            <h5 data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">Web Design</h5>
                            <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="600"></div>
                            <h6 data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">Portfolio</h6>
                            <p data-aos="fade-up" data-aos-delay="400" data-aos-duration="600">Non odio in, phasellus risus et sed sit ac proin, sit ut at elit nam laoreet, tempor elit a per vitae. Eu facilisis gravida faucib</p>
                        </div>
                    </section>
                </NavLink> */}
            </div>
        </div>
    )
}