import React from 'react';
import './allservices.css';

export default function Marketing(){
    return(
        <div className='allservices'>
            <div className='allserviceunder'>
                <div className='allservicediv'>
                    <div>
                        <h1 data-aos="fade-left" data-aos-delay="0" data-aos-duration="600">Why should you go with Marketing Collateral?</h1>
                        <p data-aos="fade-left" data-aos-delay="200" data-aos-duration="600">Either it is digital or printed marketing, our marketing collaterals serve as constant reminders of your brand and build long-term memory value. Most firms cannot dispute the relevance of social media in their online marketing strategy. We have the resources to study and offer the best plan for developing a social media strategy. This is why we provide personalized social media marketing solutions for each of our clients.</p>
                    </div>
                    {/* <div style={{width: "45%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div className='animate1'></div>
                        <div className='animate2'></div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}