import React, {useEffect} from "react";
import "./footer.css";
import { IoLogoFacebook, IoLogoTwitter, IoLogoLinkedin, IoLogoInstagram } from 'react-icons/io';

  

export default function Footer() {
    return (
        <div className="foot_container">
            <footer>
                <div className="waves">
                    <div className="wave" id="wave1"></div>
                    <div className="wave" id="wave2"></div>
                    <div className="wave" id="wave3"></div>
                    <div className="wave" id="wave4"></div>
                </div>
                <ul className="social_icon">
                    <li><a href="https://www.facebook.com/digitaltoez.pk"><IoLogoFacebook></IoLogoFacebook></a></li>
                    <li><a href="https://twitter.com/digitaltoez"><IoLogoTwitter></IoLogoTwitter></a></li>
                    <li><a href="https://www.linkedin.com/company/74018710/admin/"><IoLogoLinkedin></IoLogoLinkedin></a></li>
                    <li><a href="https://www.instagram.com/digitaltoez.pk/"><IoLogoInstagram></IoLogoInstagram></a></li>
                </ul>
                <ul className="footerMenu">
                    <li><a href="/">Home</a></li>
                    <li><a href="/services">Services</a></li>
                    <li><a href="/casestudies">Case Studies</a></li>
                    <li><a href="/aboutus">About</a></li>
                    <li><a href="/contactus">Contact</a></li>
                    <li><a href="/privacypolicy">Privacy Policy</a></li>
                    <li><a href="/termsandconditions">Terms and Conditions</a></li>
                </ul>
                <p>&copy;2021 Digital Toez | Step into the world of technology</p>
            </footer>
        </div>
    );
}