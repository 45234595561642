import React from "react";
import './termsandconditions.css';

export default function Termsandconditions(){
    return(
        <div className="termsandconditions">
            <div className="termsandconditionsunder">
                <div className="termsandconditionsheading">
                    <h2 data-aos="fade-right" data-aos-delay="0" data-aos-duration="600">Digital Toez</h2>
                    <h1 data-aos="fade-right" data-aos-delay="200" data-aos-duration="600">Terms and Conditions</h1>
                    <p data-aos="fade-right" data-aos-delay="400" data-aos-duration="600">Digital Agency – Company Terms and Conditions</p>
                </div>
                <div className="termsandconditionscontent">
                    <div className="termsandconditionscontent1">
                        <p data-aos="fade-up" data-aos-delay="500" data-aos-duration="600">1. Every package detail chosen, mentions the number of revisions that a client can avail.</p>
                        <p data-aos="fade-up" data-aos-delay="600" data-aos-duration="600">2. However, we do take in consideration extra revisions if the client is unhappy or requests a few retakes of our products/services.</p>
                        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">3. Extra charges do not apply for unaltered design concepts.</p>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">4. Revisions are sent back for review to the client within 48 hours for all logo designs.</p>
                        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">5. The turnaround time for revisions depends on the complexity.</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="400" data-aos-duration="600">Turnaround Time for New Orders</p>
                        <p data-aos="fade-up" data-aos-delay="500" data-aos-duration="600">What is turnaround time? The time taken for your orders to be completed and sent back for review/approval.</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">1. Turnaround time for new logo orders is between 24 to 48 hours.</p>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">2. For logo design packages which include 4 design concepts, the turnaround time for new logo orders will be a minimum of 48 hours.</p>
                        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">3. Turnaround time for new web designs is 48 hours.</p>
                        <p data-aos="fade-up" data-aos-delay="400" data-aos-duration="600">4. Turnaround time for new web development will be 5 business days (weekends excluded).</p>
                        <p data-aos="fade-up" data-aos-delay="500" data-aos-duration="600">5. Turnaround time begins after the client has provided our team with a complete brief and has made confirmation for the requested changes.</p>
                        <p data-aos="fade-up" data-aos-delay="600" data-aos-duration="600">6. Turnaround time for new illustrative logos is between 48 to 72 hours.</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">Refund Policies</p>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">Digital toez will deem refund policy to stand void in the following cases:</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">1. Selected Package is ‘All in One’.</p>
                        <p data-aos="fade-up" data-aos-delay="400" data-aos-duration="600">2. The initial design concept was approved by the client.</p>
                        <p data-aos="fade-up" data-aos-delay="500" data-aos-duration="600">3. The client has demanded multiple revisions (more than the approved times).</p>
                        <p data-aos="fade-up" data-aos-delay="600" data-aos-duration="600">4. The order has been canceled for reasons not related to Digital toez.</p>
                        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">5. Client has not made any contact with Digital toez after 2 weeks of order placement and confirmation.</p>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">6. Turnaround time for new illustrative logos is between 48 to 72 hours.</p>
                        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">7. Any of the company’s policies have been violated.</p>
                        <p data-aos="fade-up" data-aos-delay="400" data-aos-duration="600">8. Similar company or designer has been asked to deliver the same project.</p>
                        <p data-aos="fade-up" data-aos-delay="500" data-aos-duration="600">9. The client has not delivered the complete creative brief.</p>
                        <p data-aos="fade-up" data-aos-delay="600" data-aos-duration="600">10.  A complete design concept is requested.</p>
                        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">11. The refund claim has passed the time limit to request for a refund.</p>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">12. The business is closing or there is a change in name.</p>
                        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">13. Digital toez will not allow refund claims on issues within the organization’s partners or if the client has had a change of his mind.</p>
                        <p data-aos="fade-up" data-aos-delay="400" data-aos-duration="600">14. If the client has paid for an entire bundle and is happy with a certain service. The amount for that service will be refunded while the remaining amount for the entire bundle will be kept on hold.</p>
                        <p data-aos="fade-up" data-aos-delay="500" data-aos-duration="600">15. Refund claims will be void if the project files have been delivered to the client. </p>
                        <p data-aos="fade-up" data-aos-delay="600" data-aos-duration="600">16. Refunds and revisions cannot be applied to free of charge services.</p>
                        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">17. Clients cannot claim for logos after 30 days of purchase and 90 days for websites.</p>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">18. Following services are not included in refund claims:</p>
                        <div style={{paddingLeft: "20px"}}>
                        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600"> a. Social Media Marketing</p>
                        <p data-aos="fade-up" data-aos-delay="400" data-aos-duration="600"> b. Search Engine Optimization</p>
                        <p data-aos="fade-up" data-aos-delay="500" data-aos-duration="600"> c. Domain Registration</p>
                        <p data-aos="fade-up" data-aos-delay="600" data-aos-duration="600"> d. Web Hosting</p>
                        </div>
                        <p data-aos="fade-up" data-aos-delay="700" data-aos-duration="600">19. Websites sent for developments cannot be used to claim refunds.</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">Digital toez holds all rights for voiding any contract or service when it deems necessary. After refunds are approved, any product or service delivered will be under the ownership of Digital toez.</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">How to Claim Your Refund?</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">Reach out to us via:</p>
                        <p data-aos="fade-up" data-aos-delay="400" data-aos-duration="600">Phone Call: 03111144430</p>
                        <p data-aos="fade-up" data-aos-delay="500" data-aos-duration="600">Email: hello@digitaltoez.pk</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">Or start a live chat with one of our representatives.</p>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">1: After the request is received, Digital toez will review the request for further analysis. Once the review is completed, our team will get back in touch with you.</p>
                        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">2: Incase of approval, the clients will not hold any ownership rights to their designs</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">Quality Assurance Policy</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">1: Digital toez assures that all aspects of the design and development set forth by the client are added.</p>
                        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">2: We guarantee you 100% satisfaction.</p>
                        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">3: Limited revisions are allowed per package.</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">Service Delivery Policy</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">1: A confirmation email will be sent to the client’s email address after the product has been approved and delivered.</p>
                        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">2: For urgent orders and quick customization, contact our representatives.</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">Project Maintenance</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">1: Finalized designs are stored in our servers for 3 months till we are sure of no further changes.</p>
                        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">2: If the client is non-responsive for more than 3 months, the project is termed dormant. In case the client wants to reinstate his account, a penalty charge will be applied.</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">Help & Support</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">Our customer representatives are available 24/7 to answer any urgent queries of our clients. We make sure that our team provides you with a satisfying reply within a short amount of time.</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">Communication Policy</p>
                        <br></br>
                        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">1: All communication must be done through email. Any other sources where information regarding the project has been sent, reviews are requested or a design has been approved will not be taken into consideration unless it is done through our Email address.</p>
                        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">2: Our company is not responsible for any delayed responses if communication is not done via these sources.</p>
                        <p data-aos="fade-up" data-aos-delay="400" data-aos-duration="600">3: Digital toez is only responsible for details communicated via our Email address.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}