import React from 'react';
import './allservices.css';

export default function Photography(){
    return(
        <div className='allservices'>
            <div className='allserviceunder'>
                <div className='allservicediv'>
                    <div>
                        <h1 data-aos="fade-left" data-aos-delay="0" data-aos-duration="600">Why should you go with Photography?</h1>
                        <p data-aos="fade-left" data-aos-delay="200" data-aos-duration="600">In the digital era, you could be reading an interesting book whereas the other people could be watching a video made on the same book, while is more appealing to them in every sense. The video could be of any kind, reels or any video that appeals them. We provide videos and short films to fulfill the need of our clients and prove them the attention they deserve.</p>
                    </div>
                    {/* <div style={{width: "45%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div className='animate1'></div>
                        <div className='animate2'></div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}